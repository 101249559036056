"use client";

import Nav from "@litonarefin/components/Header/Nav";
import { Fragment, useState, memo, useEffect, useCallback, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { getIcon } from "@litonarefin/utils/icons/index";
import { sanitize } from "@litonarefin/utils/miscellaneous";
import { getSourceSite } from "@litonarefin/utils/getSourceSite";

const Header = memo(
  ({
    hideMenu = false,
    headingText,
    individualLogo,
    individualLogoPath,
    finalMenu,
    pricingSlug,
    images,
    isProductPage,
  }) => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(null);
    const [headerClass, setHeaderClass] = useState("");
    const [y, setY] = useState(0);
    const [cls, setCls] = useState({});

    const siteSource = getSourceSite();

    // const wrapperRef = useRef(null);
    const mobileRef = useRef(null);

    const handleScroll = useCallback(
      (e) => {
        const window = e.currentTarget;
        if (y > window.scrollY && window.pageYOffset > 100) {
          if (window.pageYOffset > 100) {
            setHeaderClass("jlt-is-sticky");
          }
        }

        if (y < window.scrollY || window.pageYOffset < 100) {
          if (window.pageYOffset < 100) {
            setHeaderClass("");
          } else {
            setHeaderClass("jlt-header-tf");
          }
        }
        setY(window.scrollY);
      },
      [y]
    );

    useEffect(() => {
      // setY(window.scrollY);

      if (!!hideMenu) {
        return;
      }

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }, [handleScroll]);

    useEffect(() => {
      /**
       * Mobile Menu
       */
      function handleClickOutside(event) {
        if (mobileRef.current && !mobileRef.current.contains(event.target)) {
          setIsMenuVisible(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [mobileRef]);

    useEffect(() => {
      if (!!hideMenu) {
        setCls({ className: "jlt-header-hide" });
      } else {
        setCls({ className: headerClass });
      }
    }, [hideMenu]);

    return (
      <Fragment>
        <header id="header" {...cls} style={isProductPage ? { background: "#fff" } : {}}>
          {/* <header id="header"> */}
          <div
            id="container"
            className="jlt-mx-auto md:jlt-max-w-[1280px] !jlt-px-4 md:jlt-px-8 jlt-flex jlt-items-center jlt-justify-between jlt-relative"
          >
            <div className="jlt-flex jlt-flex-shrink-0 jlt-items-center">
              {/* !!siteLogo?.miniLogo && */}

              {individualLogo && (
                <div className="jlt-flex jlt-items-center jlt-gap-x-4 jlt-mr-4">
                  <Link href={"/"}>
                    <Image
                      src={"/mini-logo.png"}
                      alt={"Mini Logo"}
                      width={32}
                      height={32}
                      sizes="100vw"
                    />
                  </Link>

                  <span>{getIcon("arrowRightAlt")}</span>
                </div>
              )}

              {individualLogo ? (
                <div className="jlt-min-w-[148px]">
                  <Link
                    href={`${process.env.NEXT_PUBLIC_NEXTJS_SITE_URL}/${individualLogoPath}` || "#"}
                  >
                    <img
                      src={individualLogo}
                      alt={individualLogoPath || "Product Logo"}
                      width={148}
                      height={28}
                      style={{
                        height: "28px",
                        objectFit: "contain",
                        objectPosition: "left center",
                      }}
                    />
                  </Link>
                </div>
              ) : (
                <Link href={"/"}>
                  <Image
                    className="jlt-block lg:jlt-hidden"
                    src={"/logo.svg"}
                    alt={"Logo"}
                    width={siteSource == "wpadminify" ? 156 : 224}
                    height={28}
                    priority={true}
                    sizes={"100vw"}
                  />
                  <Image
                    className="jlt-hidden lg:jlt-block"
                    src={"/logo.svg"}
                    alt={"Logo"}
                    width={siteSource == "wpadminify" ? 156 : 224}
                    height={28}
                    priority={true}
                    sizes={"100vw"}
                    style={{
                      height: "28px",
                    }}
                  />
                </Link>
              )}
            </div>

            <Fragment>
              {!!hideMenu ? null : (
                <div
                  // ref={mobileRef}
                  className={`${
                    isMenuVisible
                      ? "jlt-absolute jlt-top-[60px] md:jlt-top-0 jlt-left-0 md:jlt-relative jlt-px-8 md:jlt-px-0 jlt-w-full md:jlt-w-auto jlt-z-[999999] md:jlt-z-0"
                      : "jlt-hidden md:jlt-block"
                  }`}
                >
                  <div className="jlt-bg-white md:jlt-bg-transparent jlt-border-[1px] jlt-border-[#CFD2D6] lg:jlt-border-[0] jlt-rounded-md md:jlt-rounded-none">
                    <Nav
                      isMenuVisible={isMenuVisible}
                      mobileMenuOpen={mobileMenuOpen}
                      setMobileMenuOpen={setMobileMenuOpen}
                      finalMenu={finalMenu}
                      images={images}
                    />
                    <div className="md:jlt-hidden jlt-px-5 jlt-py-4">
                      <Link
                        href={siteSource == "wpadminify" ? "/account" : "/account/login"}
                        className="jlt-text-sm jlt-font-medium jlt-text-[#16213E] group-hover:jlt-text-[#256EFF] jlt-transition jlt-duration-300"
                      >
                        <span>Account</span>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              {!hideMenu ? (
                <div className="jlt-hidden lg:jlt-flex">
                  <div className="jlt-relative jlt-ml-6">
                    {siteSource == "wpadminify" ? (
                      <div className="jlt-flex jlt-gap-2">
                        <Link
                          // href={"/pricing"}
                          href={pricingSlug ? pricingSlug : "/pricing"}
                          style={{
                            background: "linear-gradient(85deg, #1F1FC1 -0.25%, #6C6CFF 100%)",
                          }}
                          className="jlt-text-white jlt-flex jlt-items-center jlt-justify-center jlt-rounded-full jlt-text-[13px] jlt-font-bold jlt-tracking-wide jlt-py-2 jlt-px-4 jlt-gap-x-2 jlt-pb-[6px]"
                        >
                          <span>Pricing</span>
                        </Link>

                        <Link
                          href={"/account"}
                          className="jlt-text-[#1A202C] jlt-bg-white jlt-flex jlt-items-center jlt-justify-center jlt-rounded-full jlt-text-[13px] jlt-font-bold jlt-tracking-wide jlt-py-2 jlt-px-4 jlt-gap-x-2 jlt-pb-[6px] jlt-border jlt-border-[#CFD2D6]"
                        >
                          <span>Sign In</span>
                        </Link>
                      </div>
                    ) : (
                      <Link
                        href={"https://pixarlabs.com/account/login"}
                        target="_blank"
                        className="jlt-bg-[--primary] jlt-text-white jlt-flex jlt-items-center jlt-justify-center jlt-rounded-full jlt-text-[13px] jlt-py-2 jlt-px-3 jlt-gap-x-2 jlt-pb-[6px]"
                      >
                        <span className="-jlt-mt-[5px]">{getIcon("user")}</span>
                        <span>Account</span>
                      </Link>
                    )}
                  </div>
                </div>
              ) : (
                <p
                  className="jlt-hide-menu-text jlt-hidden sm:jlt-block"
                  dangerouslySetInnerHTML={{
                    __html: sanitize(headingText || ""),
                  }}
                />
              )}

              {siteSource == "wpadminify" && !!hideMenu && (
                <div className="jlt-m-[0_0_0_auto]">
                  <Link
                    href={"/account"}
                    className="jlt-text-[#1A202C] jlt-bg-white jlt-flex jlt-items-center jlt-justify-center jlt-rounded-full jlt-text-[13px] jlt-font-bold jlt-tracking-wide jlt-py-2 jlt-px-4 jlt-gap-x-2 jlt-pb-[6px] jlt-border jlt-border-[#CFD2D6]"
                  >
                    <span>Sign In</span>
                  </Link>
                </div>
              )}
            </Fragment>

            {!hideMenu ? (
              <div className="jlt-flex jlt-items-center lg:jlt-hidden">
                {/* Mobile menu button */}
                <button
                  onClick={() => setIsMenuVisible(!isMenuVisible)}
                  type="button"
                  className="jlt-flex jlt-flex-col jlt-gap-y-1"
                  aria-controls="mobile-menu"
                  aria-expanded="false"
                >
                  <span
                    className={`jlt-w-[23px] jlt-h-[3px] jlt-bg-[#ababab] jlt-transition jlt-duration-200 ${
                      isMenuVisible ? "jlt-rotate-[45deg] jlt-relative jlt-top-[7px]" : ""
                    }`}
                  />
                  <span
                    className={`jlt-w-[23px] jlt-h-[3px] jlt-bg-[#ababab] ${
                      isMenuVisible ? "jlt-hidden" : "jlt-block"
                    }`}
                  />
                  <span
                    className={`jlt-w-[23px] jlt-h-[3px] jlt-bg-[#ababab] jlt-transition jlt-duration-200  ${
                      isMenuVisible ? "jlt-rotate-[-45deg]" : ""
                    }`}
                  />
                </button>
              </div>
            ) : null}
          </div>
        </header>
      </Fragment>
    );
  }
);

export default Header;
