import Link from "next/link";
import React from "react";

const MegaMenuGrid = ({ menus, images }) => {
  return (
    <div className="jlt-z-[9999] lg:jlt-opacity-0 jlt-invisible group-hover:jlt-opacity-100 group-hover:jlt-visible lg:jlt-transition lg:jlt-duration-500 jlt-ease-in-out jlt-transform group-hover:jlt--translate-y-2 jlt-relative lg:jlt-absolute lg:jlt-top-[100%] lg:-jlt-left-[150px] jlt-hidden lg:jlt-block group-hover:!jlt-block before:jlt-hidden lg:before:jlt-block normal-sub-menu mega_menu_grid">
      <ul className="jlt-grid jlt-grid-cols-1 lg:jlt-grid-cols-2 jlt-gap-x-6 jlt-gap-y-3 lg:jlt-min-w-[800px] lg:jlt-p-6 lg:jlt-shadow-[0px_0px_35px_rgba(0,0,0,0.05)] jlt-rounded-md jlt-bg-white jlt-mt-6 jlt-border-0 lg:jlt-border-[1px] jlt-border-[rgba(173, 173, 188, 0.4)]">
        {menus?.map((menu, i) => (
          <li key={i}>
            <Link
              href={menu?.menu_url || "#"}
              target={menu?.new_window ? "_blank" : "_self"}
              className="jlt-flex jlt-gap-4 jlt-p-3 jlt-group/menu-item jlt-rounded-lg hover:jlt-bg-[--primary]"
            >
              {menu?.logo && images ? (
                <div className="jlt-w-10 jlt-h-10">
                  <img width={40} height={40} src={images[menu?.logo]} />
                </div>
              ) : null}

              <div>
                <h3
                  className="jlt-text-sm jlt-font-bold jlt-leading-4 jlt-text-[#182230] group-hover/menu-item:jlt-text-[#fff]"
                  dangerouslySetInnerHTML={{
                    __html: menu?.menu_heading || "",
                  }}
                />
                <p
                  className="jlt-text-xs jlt-font-normal jlt-leading-4 jlt-text-[#4A5568] jlt-mt-1 group-hover/menu-item:jlt-text-[#fff]"
                  dangerouslySetInnerHTML={{
                    __html: menu?.menu_sub_heading || "",
                  }}
                />
              </div>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MegaMenuGrid;
